<template>
  <Link
    :href="href"
    class="min-w-[220px] rounded-md px-4 py-3 text-[15px] font-semibold text-white shadow-sm transition-all ease-in-out hover:scale-105 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:w-auto sm:min-w-min"
    :class="class"
    v-if="!href.startsWith('#') && !href.startsWith('mailto:')"
  >
    <button
      role="button"
      class="flex w-full items-center justify-center whitespace-nowrap"
    >
      <slot />
    </button>
  </Link>
  <a
    :href="href"
    class="min-w-[220px] rounded-md px-4 py-3 text-[15px] font-semibold text-white shadow-sm transition-all ease-in-out hover:scale-105 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:w-auto sm:min-w-min"
    :class="class"
    v-else
  >
    <button
      role="button"
      class="flex w-full items-center justify-center whitespace-nowrap"
    >
      <slot />
    </button>
  </a>
</template>

<script>
import { Link } from "@inertiajs/vue3";

export default {
    props: {
        href: {
            type: String,
            required: true
        },
        class: {
            type: String,
            required: false
        },
    },
    components: {
        Link
    }
}
</script>
